import React, { useState } from "react";
import logoImage from "../assets/img/illustrations/logo.png";
import menuIcon from "../assets/img/illustrations/menu-icon-13.png";
import { Routes } from "../routes";
import About from './About';
import Faqs from './Faqs';
import Terms from './Terms';
import Fee from './Fee';
import Membership from './Membership';
import Contact from './Contact';
import { Link, useHistory } from 'react-router-dom';
export default () => {
    console.log('path', window.location.pathname);
    const path = window.location.pathname;
    let page = <h1>Loading...</h1>;
    if (path == '/about-us') {
        page = <About />;
    } else if (path == '/faqs') {
        page = <Faqs />;
    }
    else if (path == '/terms-and-conditions') {
        page = <Terms />;

    } else if (path == '/membership-agreement') {
        page = <Membership />;
    }
     else if (path == '/fee-schedule') {
        page = <Fee />;
    } else if (path == '/contact') {
        page = <Contact />;
    }

    const history = useHistory();
	
	
	const storedUser = localStorage.getItem('user');
	if(storedUser) {
		//console.log('data found');
		//history.push('/dashboard');
	} else {
		//console.log('data Not found');
		//history.push('/');
	}

    return (
        <>
            <header class="header-inner">
                <div class="row">
                    <div class="col-9">
                        <div class="heder-left">
                            <div class="inner-logo">
                                <a href="/" class="nav-link">
                                    <img src={logoImage} alt="Brand" width="20" height="20" />
                                    <span class="sidebar-text">Savvey Savers Network</span></a>
                            </div>

                            <div class="inner-menu">
                                <ul>
                                    <li><Link to='faqs'>FAQ</Link></li>
									<li><Link to="terms-and-conditions">Terms & Condition</Link></li>
                                    <li><Link to='about-us'>About Us</Link></li>
                                </ul>
                            </div>


                        </div>
                    </div>
                    <div class="col-3">
                        <div class="menu-right">
							{storedUser ? (
								<div className="login-btn">
								  <Link to={Routes.Dashboard.path}>Home</Link>
								</div>
							  ) : (
							    <>
									<div className="apply-now-btn">
									  <Link to="contact">Join our Waiting List</Link>
									</div>
									<div className="login-btn">
									  <Link to={Routes.Login.path}>Log in</Link>
									</div>
								</>
							)}
                        </div>
                    </div>
					
					<div class="col-12 d-md-none">
						<div class="heder-left">
						  <div class="inner-menu">
							<ul>
							  <li><Link to='faqs'>FAQ</Link></li>
							  <li><Link to="terms-and-conditions">Terms & Condition</Link></li>
							  <li><Link to='about-us'>About Us</Link></li>
							</ul>
						  </div>


						</div>
					  </div>
					
                </div>
            </header>

            <div class="featured">
                {page}
            </div>

            <div class="copyright">
                <ul>
                    <li><a href="#"></a></li>
                    <li><a href="#"></a></li>
                </ul>
            </div>
        </>
    );
};