import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Button, Dropdown, ButtonGroup, Breadcrumb, InputGroup, Modal } from '@themesberg/react-bootstrap';
import { Routes } from "../routes";
import { Link, useHistory } from 'react-router-dom';
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { MonthRequestTable } from "../components/MonthRequestTable";
import { SavingCommitmentSaversTable } from "../components/SavingCommitmentSaversTable";
import Preloader from "../components/Preloader";
import Loader from "../components/Loader";
import { SavingCommitmentTable } from "../components/SavingCommitmentTable";
import Select from 'react-select';


export default () => {
	
	const [birthday, setBirthday] = useState("");	
	const [showDefault, setShowDefault] = useState(false);
    //const handleClose = () => setShowDefault(false);
	const [search, setSearch] = useState("");
	const [savingYear, setSavingYear] = useState("");
	const [data, setData] = useState([]);
	const [requested_months, setRequestedMonth] = useState([]);
	const [remaining_months, setRemainingMonth] = useState([]);
	const [savingCommitment, setSavingCommitment] = useState([]);
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [amount, setAmount] = useState([]);
	const [severs, setSaversList] = useState([]);
	const [isRequested, setIsRequested] = useState(false);
	
	const storedUser1 = localStorage.getItem('user');
	const userObject1 = JSON.parse(storedUser1);
	const accessToken1 = userObject1.access_token;
	
	
	const [selectedOptionsSavers, setSelectedOptionsSavers] = useState([]);
	const [selectedOptionsSaversIds, setSelectedOptionsSaversIds] = useState([]);
	const [optionsSavers, setOptionsSavers] = useState([]);
	
	const [selectedSavingCommitments, setSelectedSavingCommitments] = useState([]);
	
	const [selectedOptionsSavingCommitment, setSelectedOptionsSavingCommitment] = useState([]);
	const [selectedOptionsSavingCommitmentIds, setSelectedOptionsSavingCommitmentIds] = useState([]);
	const [optionsSavingCommitment, setOptionsSavingCommitment] = useState([]);
	
	const [showPaymentReminder, setShowPaymentReminder] = useState(false);
	const handleClosePaymentReminder = () => {
		setShowPaymentReminder(false);
	};
	
	const resetData = () => {
		window.location.reload();
	};
	
	const handleChangeSavers = (selected) => {
		setSelectedOptionsSavers(selected);
		
		// Extract IDs from selected options and update selectedIds
		const ids = selected ? selected.map(option => option.value) : [];
		setSelectedOptionsSaversIds(ids);
		
		const apiBaseUrlForGetSavingCommitmemnt = process.env.REACT_APP_API_BASE_URL+'/payment/pendingUser'; 
		const formDatasSaving = new FormData();
		formDatasSaving.append('user_id', ids);
		
		setSelectedOptionsSavingCommitment([]);
		setOptionsSavingCommitment([]);
		setSelectedOptionsSavingCommitmentIds([]);
			
		axios.post(apiBaseUrlForGetSavingCommitmemnt, formDatasSaving, {
			headers: {
				Authorization: `Bearer ${accessToken1}` // Include the access token in the Authorization header
			}
		}).then(({ data }) => {
			if(data.status==="success"){
				
				const saversCommitmentList = data.data;
        
				// Map saversList to the format required by react-select
				const newOptions = saversCommitmentList.map((saverCommitment) => ({
					value: saverCommitment.id,
					label: `${saverCommitment.savers_name} (${saverCommitment.saving_goal})`,
				 }));

				// Set the options with the mapped data
				setOptionsSavingCommitment(newOptions);
				
			}
		}).catch(error => {
		});
		
    };
	
	
	const handleChangeSavingCommitment = (selected) => {
		setSelectedOptionsSavingCommitment(selected);
		
		// Extract IDs from selected options and update selectedIds
		const ids = selected ? selected.map(option => option.value) : [];
		setSelectedOptionsSavingCommitmentIds(ids);
	}

	const [errorsSavingCommitment, setErrorsSavingCommitment] = useState({
		selectSavers: '',
		selectSavingCommitment: ''
	});
	
	const handleSubmitReminder = (event) => {
        event.preventDefault();
        // Handle form submission
		
		let valid = true;
		
		const newErrors = {
		  selectSavers: '',
		  selectSavingCommitment: ''
		};
		if(selectedOptionsSaversIds.length == "0"){
			newErrors.selectSavers = 'Please Select Savers';
			valid = false;
		}
		if(selectedOptionsSavingCommitmentIds.length == "0"){
			newErrors.selectSavingCommitment = 'Please Select Saving commitment';
			valid = false;
		}
		
		setErrorsSavingCommitment(newErrors);

		if (valid) {
			setLoading(true);

			const apiBaseURLCommitment = process.env.REACT_APP_API_BASE_URL+'/payment/reminder'; 
			
			const formDataCommitment = new FormData();
			
			formDataCommitment.append('user_saving_id', selectedOptionsSavingCommitmentIds);
			
			
			axios.post(apiBaseURLCommitment, formDataCommitment, {
			  headers: {
				Authorization: `Bearer ${accessToken}`
			  }
			}).then(({ data }) => {
				if(data.status==="success"){
					setShowDefault(false);
				}
				setLoading(false);
				window.location.reload();
			}).catch(error => {
				setLoading(false);
			});
			
		}
		
    };
	
	
	
	const storedUser = localStorage.getItem('user');
	if (!storedUser) {
	  // Redirect to the login URL
	  history.push('/'); // Replace '/login' with the actual URL of your login page
	  return false;
	}
	const userObject = JSON.parse(storedUser);
	const accessToken = userObject.access_token;
	const user_id = userObject.user_id;
	const role_id = userObject.role_id;
	
	const handleClose = () => {
		setShowDefault(false);
		
		setIsRequested(false);
		
		setFormData(prevData => ({
					...prevData,
					["isRequested"]: false
				  }));
		
	};
	
	// Create Users
	const [formData, setFormData] = useState({
		requested_month: '',
		reason: '',
		amount:'',
		saving_goal:'',
		commitment_details:'',
		buttonName: '',
		collection_year:'',
		requested_month_for:'',
		isRequested:isRequested,
		user_id:''
	});

	const [errors, setErrors] = useState({
		requested_month: '',
		reason: '',
		amount:'',
		saving_goal:'',
		commitment_details:'',
		requested_month_for:'',
		collection_year:'',
		user_id:'',
	});
  
	const [invalid, setInvalid] = useState(false);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({
		  ...formData,
		  [name]: value,
		});
		
		if(name === "amount"){
			const apiBaseUrlForMonthThroughAmount = process.env.REACT_APP_API_BASE_URL+'/get_request_amount_available_month'; 
			const formDatas = new FormData();
			formDatas.append('amount', value);
				
			axios.post(apiBaseUrlForMonthThroughAmount, formDatas, {
			})
			.then(({ data }) => {
				if(data.status==="success"){
					setRequestedMonth(data.data)
					setRemainingMonth(data.remainingMonth)
					
					setFormData(prevData => ({
						...prevData,
						["requested_month"]: ''
					  }));
					
				}
			}).catch(error => {
			});
		}
	};
  
	const handleSubmit1 = (e) => {
		e.preventDefault();
		// Get the name of the button clicked
		const buttonName = e.nativeEvent.submitter.name;

		const formsbutton = formData['buttonName'] = buttonName;

		//console.log(formData);
		let valid = true;
		const newErrors = {
		  requested_month: '',
		  reason: '',
		  amount:'',
		  saving_goal:'',
		  commitment_details: '',
		  collection_year:'',
		  user_id:'',
		};

		if(formData.isRequested === false){
			if(formData.requested_month === ""){
				newErrors.requested_month = 'Please Select Month';
				valid = false;
			}
		} else {
			if(formData.requested_month_for === ""){
				newErrors.requested_month_for = 'Please Select Requested Month';
				valid = false;
			}
			if(formData.reason === ""){
				newErrors.reason = 'Please Enter Requested Reason';
				valid = false;
			}
		}
		
		if(formData.user_id === ""){
			newErrors.user_id = 'Please Select Savers';
			valid = false;
		}
		if(formData.amount === ""){
			newErrors.amount = 'Please Select Requested Amount';
			valid = false;
		}
		if(formData.saving_goal === ""){
			newErrors.saving_goal = 'Please Select Saving Goal';
			valid = false;
		}
		if(formData.saving_goal == "9" && formData.commitment_details === ""){
			newErrors.commitment_details = 'Commitment Details is required';
			valid = false;
		}
		if(formData.collection_year === ""){
			newErrors.collection_year = 'Please Select Collection Year';
			valid = false;
		}
		setErrors(newErrors);

		if (valid) {
			setLoading(true);

			const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/request_collection_month_request'; 
			
			axios.post(apiBaseURL, formData, {
			  headers: {
				Authorization: `Bearer ${accessToken}`
			  }
			})
			.then(({ data }) => {
				if(data.status==="success"){
					setShowDefault(false);
				}
				setLoading(false);
				window.location.reload();
			}).catch(error => {
				setLoading(false);
			});
			
		}
	};
	// End Create Users

	const handleInputChangeSearch = (e) => {
		const { name, value } = e.target;
		setSearch(value);
	};
	
	const handleInputSavingYear = (e) => {
		const { name, value } = e.target;
		setSavingYear(value);
	};
	
	const sethandleRequested = (e) => {
		
		if (formData.requested_month !== "") {
			setIsRequested(false);
		} else {
			setIsRequested(true);
			
			setFormData(prevData => ({
					...prevData,
					["isRequested"]: true
				  }));
		}
		
	};
	
	
	useEffect(() => {
		
		const apiBaseURLSave = process.env.REACT_APP_API_BASE_URL+'/commitment_month'; 
		axios.get(apiBaseURLSave)
		  .then((response) => {
			setSaversList(response.data.data.savers_list)
		  })
		  .catch((error) => {
			console.error('Error fetching data:', error);
		  });
		
		
		const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/payment/ReminderPendingUser'; 
		
		axios.post(apiBaseURL, formData, {
		  headers: {
			Authorization: `Bearer ${accessToken}`
		  }
		})
		.then(({ data }) => {
			if(data.status==="success"){
				//setSaversList(data.data);
				
				const saversList = data.data;
        
				// Map saversList to the format required by react-select
				const newOptions = saversList.map((saver) => ({
				  value: saver.user_id,
				  label: saver.savers_name,
				}));

				// Set the options with the mapped data
				setOptionsSavers(newOptions);
				
			}
		}).catch(error => {
			
		});

	}, []);
	
	// commitment months
	useEffect(()=>{
	  const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/commitment_months';  
			
		axios.get(apiBaseURL)
		.then(response => {
			if(response.data.status==="success"){
			  //setRequestedMonth(response.data.data);
			  
			  const filteredCommitments = response.data.saving_commitment_list.filter(commitments => commitments.is_show === '0');
			  const filterCommit = filteredCommitments.map(commit => commit.name);
			  setSavingCommitment(filterCommit);
			  setAmount(response.data.commitment_amount)
			  
			  setSavingCommitment(response.data.saving_commitment_list);
			  
			}
			setLoading(false);
		})
		.catch(error => {
		  console.error('Error:', error);
		});
	},[]);
	
	
	const getCurrentYear = () => {
	  const currentYear = new Date().getFullYear();
	  const years = [];

	  for (let i = 0; i <= 10; i++) {
		years.push(currentYear + i);
	  }

	  return years;
	};

	const yearsList = getCurrentYear();
	
	
	if (loading) {
		//return <Preloader show={loading ? true : false} />
	}
		
	const btnWidth = {
		width: '250px',
		margin: '10px'
	};	
	
  return (
    <>
	  {loading && <Loader/>}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Saving Commitments</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Savings Commitments</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="align-items-center">
          <Col xs={8} md={6} lg={3} xl={8}>
			<div class="d-flex gap-2">
				<Col xl={4} className="d-table-cell">
					<InputGroup>
					  <InputGroup.Text>
						<FontAwesomeIcon icon={faSearch} />
					  </InputGroup.Text>
					  <Form.Control type="text" placeholder="Search" name="searchKeyword" onChange={handleInputChangeSearch}/>
					</InputGroup>
				</Col>
				<Col xl={3} className="d-table-cell">
					<InputGroup>
					  <Col>
						<Form.Select name="savingYear" onChange={handleInputSavingYear}>
							<option value="">Choose Saving Year..</option>
						  {[...Array(20)].map((_, index) => {
							const year = 2021 + index;
							return (
							  <option key={year} value={year}>
								{year}
							  </option>
							);
						  })}
						</Form.Select>
					  </Col>
					</InputGroup>
				</Col>
				<Col xl={1} className="d-table-cell">
					<Button className="reset-btn" variant="primary" size="md" onClick={() => resetData()}>Reset</Button>
				</Col>
			 </div>
          </Col>
          <Col xs={4} md={2} xl={4} className="ps-md-0 text-end">
            <ButtonGroup>
			{role_id == "1" && (
				<Button variant="primary" className="m-1" size="sm" onClick={() => setShowPaymentReminder(true)}>Send Reminder</Button>
			)}
				<Button variant="primary" className="m-1" size="sm" onClick={() => setShowDefault(true)}>New Savings Commitment</Button>
			</ButtonGroup>
          </Col>
        </Row>
      </div>

      <SavingCommitmentTable data={search} savingYear={savingYear}/>
	  
	  
	  <React.Fragment>
		  <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
			<Modal.Header>
			  <Modal.Title className="h6">New Saving Commitment</Modal.Title>
			  <Button variant="close" aria-label="Close" onClick={handleClose} />
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col xs={12} xl={12}>
						<Card border="light" className="bg-white shadow-sm mb-4">
						  <Card.Body>
							<h5 className="mb-4">New Saving Commitment</h5>
							<Form onSubmit={handleSubmit1}>
							  <Row>
								<Col md={12} className="mb-3">
									<Form.Group id="saving_goal">
										<Form.Label>Savers <span class="error-message">*</span></Form.Label>
										<Form.Select defaultValue="" name="user_id" onChange={handleInputChange}>
										  <option value="">Choose Savers Name</option>
										  {severs.map((saver, index) => (
											<option key={saver.id} value={saver.id}>
											  {saver.name}
											</option>
										  ))}
										</Form.Select>
									</Form.Group>
									<div className="error-message">{errors.user_id}</div>
								</Col>
								<Col md={12} className="mb-3">
									<Form.Group id="amount">
										<Form.Label>Savings Commitment Amount <span class="error-message">*</span></Form.Label>
										<Form.Select defaultValue="" name="amount" onChange={handleInputChange}>
										  <option value="">Choose Commitment Amount</option>
										  {amount
										  .filter(item => item.is_show === "1")
										  .map(({ id, amount }) => (
											<option key={amount} value={amount}>
											  £{amount}
											</option>
										  ))}
										</Form.Select>
									</Form.Group>
									 <div className="error-message">{errors.amount}</div>
								</Col>
								{!isRequested && 
								<Col md={12} className="mb-3">
								  <Form.Group id="requested_month">
									<Form.Label>Collection Month <span class="error-message">*</span></Form.Label>
									<Form.Select defaultValue="" name="requested_month" onChange={handleInputChange}>
									  <option value="">Choose Month</option>
									  {requested_months.map((month, index) => (
											<option key={month.month_name} value={month.id}>
												{month.month_name}
											</option>
										))}
									</Form.Select>
								  </Form.Group>
								  <div className="error-message">{errors.requested_month}</div>
								</Col>
								}
								
								{isRequested && 
									<Col md={12} className="mb-3">
									  <Form.Group id="requested_month_for">
										<Form.Label>Month Requested <span class="error-message">*</span></Form.Label>
										<Form.Select defaultValue="" name="requested_month_for" onChange={handleInputChange}>
										  <option value="">Choose Requested Month For</option>
											{remaining_months.map((month, index) => (
												<option key={month.month_name} value={month.id}>
													{month.month_name}
												</option>
											))}
										</Form.Select>
									  </Form.Group>
									  <div className="error-message">{errors.requested_month_for}</div>
									</Col>
								}
								
								<Col md={12} className="mb-3">
									<Form.Group id="collection_year">
										<Form.Label>Collection Year <span class="error-message">*</span></Form.Label>
										<Form.Select defaultValue="" name="collection_year" onChange={handleInputChange}>
										  <option value="">Choose Collection Year</option>
										  {yearsList.map((year, index) => (
											<option key={year} value={year}>
											  {year}
											</option>
										  ))}
										</Form.Select>
									</Form.Group>
									<div className="error-message">{errors.collection_year}</div>
								</Col>
								
								<Col md={12} className="mb-3">
									<Form.Group id="saving_goal">
										<Form.Label>Savings Commitment Goal <span class="error-message">*</span></Form.Label>
										<Form.Select defaultValue="" name="saving_goal" onChange={handleInputChange}>
										  <option value="">Choose Saving goal</option>
										  {savingCommitment.map((goal, index) => (
											<option key={goal.id} value={goal.id}>
											  {goal.name}
											</option>
										  ))}
										</Form.Select>
									</Form.Group>
									<div className="error-message">{errors.saving_goal}</div>
								</Col>
								{formData.saving_goal === "9" && (
									<Col md={12} className="mb-3">
									  <Form.Group id="commitment_details">
										<Form.Control type="text" name="commitment_details" placeholder="Enter Saving Commitment goal" onChange={handleInputChange} />
									  </Form.Group>
									  <div className="error-message">{errors.commitment_details}</div>
									</Col>
								)}
								{isRequested && 
								<Col md={12} className="mb-3">
								  <Form.Group id="Reason">
									<Form.Label>Reason</Form.Label>
									<Form.Control as="textarea" name="reason" placeholder="Enter Requested Reason" onChange={handleInputChange} rows="3" />
								  </Form.Group>
								  <div className="error-message">{errors.reason}</div>
								</Col>
								}
							  </Row>
							  <div className="mt-3 center">
								{!isRequested && 
								<Button variant="primary" onClick={() => sethandleRequested(true)} style={formData.requested_month !== "" ? { backgroundColor: 'grey', borderColor: 'grey', ...btnWidth } : btnWidth}>Request Collection Month</Button>
								}
								&nbsp; <br/><Button variant="primary" name="save" data-button-name="save" type="submit" style={btnWidth}>Save</Button>&nbsp;&nbsp; <br/>
								<Button variant="primary" onClick={handleClose} style={btnWidth}>Cancel</Button>&nbsp;
							  </div>
							</Form>
						  </Card.Body>
						</Card>
					</Col>
				  </Row>
			</Modal.Body>
		  </Modal>
		</React.Fragment>
		
		
		<React.Fragment>
            <Modal as={Modal.Dialog} centered show={showPaymentReminder} onHide={handleClosePaymentReminder}>
                <Modal.Header>
                    <Modal.Title className="h6">Payment Reminder</Modal.Title>
                    <Button variant="close" aria-label="Close" onClick={handleClosePaymentReminder} />
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12} xl={12}>
                            <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Body>
                                    <h5 className="mb-4">Send Reminder Notification</h5>
                                    <Form onSubmit={handleSubmitReminder}>
                                        <Row>
                                            <Col md={12} className="mb-3">
												<Form.Group id="saving_goal">
													<Form.Label>Savers <span class="error-message">*</span></Form.Label>
													<Select
													  options={optionsSavers}
													  isMulti
													  isClearable
													  value={selectedOptionsSavers}
													  onChange={handleChangeSavers}
													  placeholder="Select Savers"
													/>
													
												</Form.Group>
												<div className="error-message">{errorsSavingCommitment.selectSavers}</div>
											</Col>
											
											
											<Col md={12} className="mb-3">
												<Form.Group id="saving_goal">
													<Form.Label>Saving Commitment <span class="error-message">*</span></Form.Label>
													<Select
													  options={optionsSavingCommitment}
													  isMulti
													  isClearable
													  value={selectedOptionsSavingCommitment}
													  onChange={handleChangeSavingCommitment}
													  placeholder="Select Saving Commitment"
													/>
													
												</Form.Group>
												<div className="error-message">{errorsSavingCommitment.selectSavingCommitment}</div>
											</Col>
											
                                        </Row>
                                        <div className="mt-3 center">
                                            <Button variant="primary" type="submit">Send</Button>
                                            &nbsp;&nbsp;
                                            <Button variant="primary" onClick={handleClosePaymentReminder}>Cancel</Button>
                                        </div>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </React.Fragment>
	  
    </>
  );
};
