import React, { useState } from 'react';
import axios from 'axios';
import { Form, Row, Col, Button } from '@themesberg/react-bootstrap';
import Swal from 'sweetalert2';
import Preloader from "../components/Preloader";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '+44',
    savings_commitment: '',
    referred: false,
    referred_by: ''
  });

  const [errors, setErrors] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    savings_commitment: '',
    referred_by: ''
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;

    const newErrors = {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      savings_commitment: '',
      referred_by: ''
    };

    // Validation checks
    if (formData.first_name === "") {
      newErrors.first_name = 'First name is required.';
      valid = false;
    }

    if (formData.last_name === "") {
      newErrors.last_name = 'Last name is required.';
      valid = false;
    }

    if (formData.email === "") {
      newErrors.email = 'Email is required.';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email format is invalid.';
      valid = false;
    }

    if (formData.phone === "") {
		newErrors.phone = "Phone number is required.";
		valid = false;
	} else if (formData.phone.length < 11) {
		newErrors.phone = "Phone number must be at least 11 digits.";
		valid = false;
	}

    if (formData.savings_commitment === "") {
      newErrors.savings_commitment = 'Please select a savings commitment.';
      valid = false;
    }

    if (formData.referred && formData.referred_by === "") {
      newErrors.referred_by = 'Please provide the name of the member who referred you.';
      valid = false;
    }

    setErrors(newErrors);
    
    if (valid) {
	  setLoading(true);	
      const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/writing/create';
      try {
        const response = await axios.post(apiBaseURL, formData, {
          headers: {}
        });

        if (response.data.status === "success") {
          Swal.fire({
			title: "Success!",
			text: "You have been successfully added to our Waiting List",
			icon: "success",
			timer: 5000, // Automatically close after 5 seconds
			showConfirmButton: false, // Remove the OK button
			willClose: () => {
			  // This function runs just before the alert closes
			  window.location.href = "/"; // Redirect to the landing page
			}
		  });
		  
		  setLoading(false);
          
          // Clear form after successful submission
          setFormData({
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            savings_commitment: '',
            referred: false,
            referred_by: ''
          });
        } else {
		  setLoading(false);	
          Swal.fire("Error", "There was an issue submitting your form. Please try again.", "error");
        }
      } catch (error) {
		 setLoading(false); 
        console.error('There was an error submitting the form!', error);
        Swal.fire("Error", "There was an error submitting the form. Please try again later.", "error");
      }
    }
  };

  return (
    <React.Fragment>
	  {loading && <Preloader show={true} />}
      <div className="static-pages">
        <h2 className="contact-heading">Register your Details</h2>
        <div className="contact-form-section">
          <Form onSubmit={handleSubmit} className="contact-form">
            <Row>
              <Col xs={12} md={6}>
                <Form.Group controlId="formFirstName">
                  <Form.Control
                    type="text"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    placeholder="First Name *"
                    isInvalid={!!errors.first_name}
                  />
                  <Form.Control.Feedback type="invalid">{errors.first_name}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="formLastName">
                  <Form.Control
                    type="text"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    placeholder="Last Name *"
                    isInvalid={!!errors.last_name}
                  />
                  <Form.Control.Feedback type="invalid">{errors.last_name}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Form.Group controlId="formEmail">
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email *"
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Form.Group controlId="formPhone">
                  <Form.Control
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Phone Number *"
                    isInvalid={!!errors.phone}
                  />
                  <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Form.Group controlId="formSavingsCommitment">
                  <Form.Select
                    name="savings_commitment"
                    value={formData.savings_commitment}
                    onChange={handleChange}
                    isInvalid={!!errors.savings_commitment}
                  >
                    <option value="">What is your monthly savings goal?</option>
                    <option value="250">£250</option>
                    <option value="500">£500</option>
                    <option value="750">£750</option>
                    <option value="1000">£1000</option>
                    <option value="1000+">£1000+</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.savings_commitment}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Form.Check
                  type="checkbox"
                  label="Referred by a Member"
                  name="referred"
                  checked={formData.referred}
                  onChange={handleChange}
                />
                {formData.referred && (
                  <Form.Group controlId="formReferredBy">
                    <Form.Control
                      type="text"
                      name="referred_by"
                      value={formData.referred_by}
                      onChange={handleChange}
                      placeholder="Member's Name *"
                      isInvalid={!!errors.referred_by}
                    />
                    <Form.Control.Feedback type="invalid">{errors.referred_by}</Form.Control.Feedback>
                  </Form.Group>
                )}
              </Col>
            </Row>

            <Button type="submit">Submit</Button>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
};

// Internal CSS styles
/*
const styles = {
  staticPages: {
    padding: '100px',
    paddingTop: '100px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  },
  contactHeading: {
    marginBottom: '20px',
    fontSize: '24px',
    color: '#333',
    textAlign: 'center',
  },
  contactDiv: {
    maxWidth: '600px',
    margin: '0 auto',
  },
  contactForm: {
    display: 'flex',
    flexDirection: 'column',
  },
  submitButton: {
    padding: '12px',
    borderRadius: '4px',
    backgroundColor: '#4472C4',
    color: 'white',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  }
};

*/

export default Contact;
