import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Alert, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import BgImage from "../assets/img/illustrations/login_img.jpg";
import QRImage from "../assets/img/illustrations/images.png";
import logoImage from "../assets/img/illustrations/logo.png";
import menuIcon from "../assets/img/illustrations/menu-icon-13.png";
import bannerImg from "../assets/img/illustrations/banner-img.webp";
import axios from 'axios';
import { Routes } from "../routes";
import { Link, useHistory } from 'react-router-dom';
import Loader from "../components/Loader";
export default () => {

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  const [invalid, setInvalid] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const history = useHistory();
  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate email and password
    let valid = true;
    const newErrors = {
      email: '',
      password: '',
    };
    if (!formData.email || !formData.email.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
      newErrors.email = 'Please enter a valid email address';
      valid = false;
    }

    if (formData.email === "") {
      newErrors.email = 'Email field is required';
      valid = false;
    }
    if (!formData.password || formData.password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters long';
      valid = false;
    }

    if (formData.password === "") {
      newErrors.password = 'Password field is required';
      valid = false;
    }
    setErrors(newErrors);
    if (valid) {
      setLoading(true);
      // Read the environment variable
      const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

      // Perform login logic here, for example, sending data to an API
      axios.defaults.baseURL = apiBaseURL;
      axios.post('/login', formData)
        .then(({ data }) => {
          if (data.status === "success") {
            setLoading(false);
            // Save user data in localStorage
            localStorage.setItem('user', JSON.stringify(data.content));

            // Redirect to another route (e.g., dashboard)
            if ((data.content.is_saving_commitment === "0") && (data.content.role_id != 1)) {
              history.push('/saving_commitment');
            } else {
              history.push('/dashboard');
            }
          } else {
            setLoading(false);
            setInvalid(true);
          }
        }).catch(error => {
          setLoading(false);
          setInvalid(true);
        });
    }
  };
  const divStyle = {
    height: '100%', // Note: Make sure to enclose 100% in quotes

    left: 0,
    top: 0,
    overflow: 'hidden', // Note: Make sure to enclose 'hidden' in quotes
    position: 'fixed'   // Note: Make sure to enclose 'fixed' in quotes
  };
  const storedUser = localStorage.getItem('user');
  if (storedUser) {
    console.log('data found');
    history.push('/dashboard');
  } else {
    console.log('data Not found');
    //history.push('/');
  }
  return (
    <>
      <header class="header-inner">
        <div class="row">
          <div class="col-9">
            <div class="heder-left">
              <div class="inner-logo">
                <a href="#" class="nav-link">
                  <img src={logoImage} alt="Brand" width="20" height="20" />
                  <span class="sidebar-text">Savvey Savers Networks</span></a>
              </div>

              <div class="inner-menu">
                <ul>
                  <li><Link to='faqs'>FAQ</Link></li>
                  <li><Link to="terms-and-conditions">Terms & Condition</Link></li>
                  <li><Link to='about-us'>About Us</Link></li>
				  <li><a href='https://g.co/kgs/yDegUC9' target="_blank">Reviews</a></li>
                </ul>
              </div>


            </div>
          </div>
          <div class="col-3">
            <div class="menu-right">
			  <div class="apply-now-btn"><Link to='contact'>Join our Waiting List</Link></div>
              <div class="login-btn"><Link to={Routes.Login.path}>Log in</Link></div>
				  {/*
              <div class="toggle-btn"><a href="#"><img src={menuIcon} alt="" style={{ width: '28px' }} /></a></div>
				  */}
            </div>
          </div>

          <div class="col-12 d-md-none">
            <div class="heder-left">
              <div class="inner-menu">
                <ul>
                  <li><Link to='faqs'>FAQ</Link></li>
                  <li><Link to="terms-and-conditions">Terms & Condition</Link></li>
                  <li><Link to='about-us'>About Us</Link></li>
				  <li><a href='https://g.co/kgs/yDegUC9' target="_blank">Reviews</a></li>
                </ul>
              </div>


            </div>
          </div>


        </div>
      </header>

      <div class="featured">
        <div class="feature-img"><img src={bannerImg} alt="" /></div>
        <div class="feature-content feature-content-home">
          <div class="row">
            {/* <div class="col-md-4"><img src={QRImage} alt=""/></div> */}
            <div class="col-md-8">
              <div class="feature-content-inner">
                <h2>Savvey Savers Network</h2>
                <p>Welcome to Savvey Savers Network Limited!</p>
                <p>Embark on a collective journey towards financial success with Savvey Savers Network. Here, we embrace a community-driven approach, firmly believing that "little drops together can make mighty savings." Our platform is dedicated to empowering you through the synergy of shared resources.</p>
                <p>Our Vision: A Community-Driven Financial Future</p>
                <p>At Savvey Savers Network, we envision a collaborative space where your financial aspirations come to life. Whether you're saving for a mortgage deposit, funding education, investing in properties, or pursuing other dreams, we're here to support you. Together, We Thrive!</p>
                <p>Start your journey with Savvey Savers Network today and witness the transformative power of collective financial strength. Welcome to a community where your dreams matter, and together, we make them a reality.</p>
                {/*
			<a href="#">Book Now</a> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="copyright">
        <ul>
          <li><a href="#"></a></li>
          <li><a href="#"></a></li>
        </ul>
      </div>
    </>
  );
};